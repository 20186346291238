<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Pension Calculator</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container class="tw-p-6 tw-bg-gray-100">
        <div class="tw-container tw-mx-auto">
          <v-row>
            <v-col cols="12" md="6" class="tw-space-y-6">
              <v-card class="tw-bg-blue-50 tw-p-2" flat>
                <v-text-field
                  v-model="yearsInScheme"
                  label="Total Number of Years to Retirement"
                  type="number"
                  placeholder="e.g., 20"
                  hint="Enter the number of years until retirement"
                  persistent-hint
                  @input="calculateProjection"
                  outlined
                  dense
                ></v-text-field>
              </v-card>

              <!-- Frequency Selection -->
              <v-card class="tw-bg-blue-50 tw-p-2" flat>
                <v-select
                  v-model="frequency"
                  :items="['Monthly', 'Quarterly', 'SemiAnnually', 'Annually']"
                  label="Frequency of Payment"
                  hint="Select how often you will contribute"
                  persistent-hint
                  @change="calculateProjection"
                  outlined
                  dense
                ></v-select>
              </v-card>

              <!-- Transfer Amount -->
              <v-card class="tw-bg-green-50 tw-p-2" flat>
                <v-text-field
                  v-model="transferAmount"
                  label="Transfer Amount (if any)"
                  type="number"
                  placeholder="e.g., 50000"
                  hint="Enter any lump sum transfer amount"
                  persistent-hint
                  @input="calculateProjection"
                  outlined
                  dense
                ></v-text-field>
              </v-card>

              <!-- Monthly Contribution -->
              <v-card class="tw-bg-blue-50 tw-p-2" flat>
                <v-text-field
                  v-model="monthlyContribution"
                  label="Contribution Amount Based on Frequency Selected"
                  type="number"
                  placeholder="e.g., 10000"
                  hint="Enter your contribution amount"
                  persistent-hint
                  @input="calculateProjection"
                  outlined
                  dense
                ></v-text-field>
              </v-card>

              <!-- Annuity Option -->
              <v-card class="tw-bg-blue-50 tw-p-2" flat>
                <v-select
                  v-model="annuityChoice"
                  :items="['Yes', 'No']"
                  label="Would you like to include an annuity option?"
                  hint="Choose whether to include annuity payments"
                  persistent-hint
                  @change="handleAnnuityChange"
                  outlined
                  dense
                ></v-select>
              </v-card>
            </v-col>

            <!-- Results Section -->
            <v-col cols="12" md="6">
              <v-card class="tw-p-4" elevation="6">
                <h2 class="tw-text-2xl tw-font-bold tw-mb-6 tw-flex tw-items-center">
                  <v-icon large color="yellow darken-3" class="tw-mr-2">mdi-chart-line</v-icon>
                  Projection Results
                </h2>

                <v-card class="tw-bg-gradient-to-r tw-from-blue-100 tw-to-blue-300 tw-p-6" flat>
                  <div class="tw-text-center">
                    <h3 class="tw-text-xl tw-font-medium tw-mb-4">Total Pension at Retirement:</h3>
                    <p class="tw-text-3xl tw-font-bold tw-text-green-800">
                      {{ formatCurrency(totalPension) }}
                    </p>

                    <div class="tw-mt-4 tw-space-y-2">
                      <p><span class="tw-font-semibold">Years in Scheme:</span> {{ yearsInScheme }}</p>
                      <p><span class="tw-font-semibold">Interest Rate:</span> 10%</p>
                      <p><span class="tw-font-semibold">Total Invested:</span> {{ formatCurrency(totalInvested) }}</p>
                      <p><span class="tw-font-semibold">Total Interest Earned:</span> {{ formatCurrency(totalInterest) }}</p>
                    </div>

                    <div v-if="showAnnuityField" class="tw-mt-6 tw-p-4 tw-bg-yellow-50 tw-rounded">
                      <p class="tw-text-xl tw-font-medium">Annuity Amount Payable:</p>
                      <p class="tw-text-2xl tw-font-bold tw-text-yellow-800">
                        {{ formatCurrency(annuityPayment) }} Monthly
                      </p>
                    </div>
                  </div>
                </v-card>
              </v-card>
            </v-col>
          </v-row>

          <!-- Info Section -->
          <v-card class="tw-mt-8 tw-p-4 tw-bg-yellow-50" flat>
            <div class="tw-p-4">
              <h3 class="tw-text-xl tw-font-bold tw-mb-3 tw-text-yellow-800">
                Invest in Your Future with Confidence
              </h3>
              <p class="tw-text-gray-700">
                Planning for retirement is one of the most crucial financial decisions you will ever make.
                By using our pension calculator, you can get a clear picture of your future savings and make informed decisions today.
                Please note that projections are based on an estimated rate of return of 10%.
              </p>
              <p class="tw-mt-4 tw-text-gray-700">
                For personalized advice or any inquiries, please email us at
                <a href="mailto:pensions@apalife.co.ke" class="tw-text-blue-600 hover:tw-underline">pensions@apalife.co.ke</a>
              </p>
            </div>
          </v-card>

          <!-- Action Buttons -->
          <div class="tw-text-center tw-mt-6">
            <v-btn
              color="primary"
              large
              class="tw-mr-4"
              @click="downloadPDF"
            >
              <v-icon left>mdi-download</v-icon>
              Download Projections as PDF
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  name: 'PensionCalculatorModal',

  setup() {
    const dialog = ref(false);
    const yearsInScheme = ref(0);
    const monthlyContribution = ref(0);
    const transferAmount = ref(0);
    const frequency = ref('Monthly');
    const annuityChoice = ref('Yes');
    const showAnnuityField = ref(true);
    const totalInvested = ref(0);
    const totalInterest = ref(0);
    const totalPension = ref(0);
    const annuityPayment = ref(0);

    const freqMap = {
      Monthly: 12,
      Quarterly: 4,
      SemiAnnually: 2,
      Annually: 1,
    };

    const calculateProjection = () => {
      const frequencyMultiplier = freqMap[frequency.value] || 12;
      const interestRate = 0.10;
      const adjustedRate = interestRate / frequencyMultiplier;

      const totalMonths = yearsInScheme.value * 12;
      const totalPeriods = totalMonths / (12 / frequencyMultiplier);

      const contributionPerPeriod = monthlyContribution.value;
      const futureValueOfContributions = contributionPerPeriod
        * (((1 + adjustedRate) ** totalPeriods - 1) / adjustedRate)
        * (1 + adjustedRate);

      const futureValueOfTransfers = transferAmount.value
        * ((1 + interestRate) ** yearsInScheme.value);

      totalInvested.value = contributionPerPeriod * totalPeriods + transferAmount.value;
      totalPension.value = futureValueOfContributions + futureValueOfTransfers;
      totalInterest.value = totalPension.value - totalInvested.value;

      if (annuityChoice.value === 'Yes') {
        // eslint-disable-next-line no-use-before-define
        calculateAnnuity();
      }
    };

    const calculateAnnuity = () => {
      const principle = totalPension.value;
      annuityPayment.value = principle * 0.01;
    };

    const handleAnnuityChange = () => {
      showAnnuityField.value = annuityChoice.value === 'Yes';
      calculateProjection();
    };

    const formatCurrency = (value) => new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'KSH',
    }).format(value);

    const downloadPDF = async () => {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();

      doc.setFontSize(22);
      doc.setFont('helvetica', 'bold');
      doc.text('APA Insurance - Pension Projection',
        doc.internal.pageSize.getWidth() / 2, 30, null, null, 'center');

      const headers = [['Pension Detail', 'Value']];
      const data = [
        ['Total Pension at Retirement', formatCurrency(totalPension.value)],
        ['Years in Scheme', yearsInScheme.value],
        ['Interest Rate', '10%'],
        ['Total Invested', formatCurrency(totalInvested.value)],
        ['Total Interest Earned', formatCurrency(totalInterest.value)],
      ];

      if (annuityChoice.value === 'Yes') {
        data.push(['Monthly Annuity Payment', formatCurrency(annuityPayment.value)]);
      }

      doc.autoTable({
        startY: 40,
        head: headers,
        body: data,
        theme: 'grid',
        headStyles: { fillColor: [60, 141, 188] },
      });
      doc.save('Pension_Projection.pdf');
    };

    const openModal = () => {
      dialog.value = true;
    };

    const closeModal = () => {
      dialog.value = false;
    };

    return {
      dialog,
      yearsInScheme,
      monthlyContribution,
      transferAmount,
      frequency,
      annuityChoice,
      showAnnuityField,
      totalInvested,
      totalInterest,
      totalPension,
      annuityPayment,
      calculateProjection,
      handleAnnuityChange,
      formatCurrency,
      downloadPDF,
      openModal,
      closeModal,
    };
  },
};
</script>
<style  lang="scss" scoped>
.animate-bounce {
  animation: bounce 1s infinite
}
</style>
